require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
//  Custom Scripts
require("custom/global")
require("custom/nmi_payments")
require("custom/monthly_yearly_toggle")

import 'bootstrap'
import loadPaymentButton from 'custom/nmi_payments'
import monthlyYearlyToggle from 'custom/monthly_yearly_toggle'
import convertUTCtoTimeZone from 'custom/global'
import "chartkick/highcharts"

document.addEventListener("turbolinks:load", () => {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()

    // there HAS to be a better way to do this...
    let shareChoices = document.querySelectorAll('input[name="share_type"]');
    for (var i = 0; i < shareChoices.length; i++) {
      shareChoices[i].addEventListener('change', e => {
        if(e.target.id == 'share_type_public' && e.target.checked) {
          document.getElementById('public_share_groups').className ='d-block';
        } else {
          document.getElementById('public_share_groups').className ='d-none';
        }
      })
    };

    monthlyYearlyToggle();
    loadPaymentButton();
    convertUTCtoTimeZone();
});


