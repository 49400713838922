function reveal(element) {
  element.classList.remove('d-none');
  element.classList.add('d-block');
}

function hide(element) {
  element.classList.remove('d-block');
  element.classList.add('d-none');
}

export default function monthlyYearlyToggle() {
  let frequencyChoices = document.querySelectorAll('input[name="payment_frequency_options"]');
  let yearlyComponents = document.getElementsByClassName('yearly');
  let monthlyComponents = document.getElementsByClassName('monthly')
  for (var i = 0; i < frequencyChoices.length; i++) {
    frequencyChoices[i].addEventListener('change', e => {
      if(e.target.id == 'yearly' && e.target.checked) {
        for (var j = 0; j < yearlyComponents.length; j++) {
          reveal(yearlyComponents[j]);
        }
        for (var j = 0; j < monthlyComponents.length; j++) {
          hide(monthlyComponents[j]);
        }
      } else {
        for (var j = 0; j < monthlyComponents.length; j++) {
          reveal(monthlyComponents[j]);
        }
        for (var j = 0; j < monthlyComponents.length; j++) {
          hide(yearlyComponents[j]);
        }
      }
    })
  };
}
