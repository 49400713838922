export default function convertUTCtoTimeZone() {
  let elements = document.getElementsByClassName('convert-utc-to-time-zone');
  for (var j = 0; j < elements.length; j++) {
    const element = elements[j];
    const utcString = element.textContent;
    try {
      const utcDate = new Date(utcString);
      const localeTime = utcDate.toLocaleTimeString("en" , { timeStyle: "short" });
      element.innerHTML = localeTime;
    } catch (e) {
      element.innerHTML = utcString;
    }    
  }
}
