import consumer from "./consumer"
 
consumer.subscriptions.create("NotificationsChannel", {
  connected() {
    console.log("connected to NotificationsChannel ")
  },

  received(data) {
    $('#toast_container').append(data.html);
    $('.toast').toast('show');
  }
})
